import Vue from 'vue';
import VueRouter from 'vue-router';
import FirstPage from '@/views/FirstPage'
import Articles from '@/views/Articles'
import MainPage from '@/views/MainPage'
import NotFind from '@/views/NotFind'
import Comments from '@/views/Comments'
import ToolPage from '@/views/ToolPage'
import DynamicPage from '@/views/DynamicPage'
import Art01 from '@/components/articles/01'
import Art02 from '@/components/articles/02'
import Art03 from '@/components/articles/03'
import Art04 from '@/components/articles/04'
import Art05 from '@/components/articles/05'
import Art06 from '@/components/articles/06'
import Art07 from '@/components/articles/07'
import Art08 from '@/components/articles/08'
import Art09 from '@/components/articles/09'
import Tool01 from '@/components/tools/01'
import Tool02 from '@/components/tools/02'
import Tool03 from '@/components/tools/03'
import Tool04 from '@/components/tools/04'
import Tool05 from '@/components/tools/05'
import Tool06 from '@/components/tools/06'
import Tool07 from '@/components/tools/07'
import Tool08 from '@/components/tools/08'
import Tool09 from '@/components/tools/09'
Vue.use(VueRouter)

const router = new VueRouter({
  mode:'history',
  routes:[
    {path:'/first', component:FirstPage,name:'first'},
    {path:'/', redirect:'first'},
    {path:'/articles', component:Articles,children:[
        {path:'01',component:Art01},
        {path:'02',component:Art02},
        {path:'03',component:Art03},
        {path:'04',component:Art04},
        {path:'05',component:Art05},
        {path:'06',component:Art06},
        {path:'07',component:Art07},
        {path:'08',component:Art08},
        {path:'09',component:Art09},
    ]},
    {path:'/main', component:MainPage},
    {path:'/dynamic', component:DynamicPage},
    {path:'/comments', component:Comments},
    {path:'/tools', component:ToolPage,children:[
      {path:'01',component:Tool01},
      {path:'02',component:Tool02},
      {path:'03',component:Tool03},
      {path:'04',component:Tool04},
      {path:'05',component:Tool05},
      {path:'06',component:Tool06},
      {path:'07',component:Tool07},
      {path:'08',component:Tool08},
      {path:'09',component:Tool09},
  ]},
    {path:'*', component:NotFind},
  ]
})

export default router