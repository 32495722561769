<template>
  <div class="DP">
    <DyTop></DyTop>
  </div>
</template>

<script>
import DyTop from '@/components/DyTop.vue'
export default {
    components:{
        DyTop,
    }
}
</script>

<style>
.DP{
    display: flex;
    direction: columns;
    height: 1000px;
    background-color: #eff3f5;
    width: 100%;
}

</style>