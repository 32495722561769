<template>
  <div class="main">
    <BodySection></BodySection>

  </div>
</template>

<script>
import BodySection from '@/components/BodySection.vue'

export default {
  name:'ThisMain',
  components:{
    BodySection,
  }

}
</script>

<style scoped>
.main{
  display: flex;
  flex-direction: column;
  margin: auto; /* 居中 */
}
</style>