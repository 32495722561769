<template>
  <div class="right-component">
    <h1>基于OpenCV的人脸识别系统</h1>
        <div class="maintext">
          <h3>人脸识别程序介绍</h3><br>
          <p>
            <ul>
              <li>1. 实现利用mysql数据库存储照片信息以及人员信息</li>
              <li>2. 能够调用数据库信息生成识别特征文件trainer.csv</li>
              <li>3. 实现对人员以及人脸数据的增删改查</li>
              <li>4. 能够同时识别多个人脸数据</li> 
              <li>5. GUI窗口能够实现数据库登录</li>   
            </ul></p>
  </div>
  </div>
</template>

<script>
export default {
    name:'ArtFirst,'
}
</script>

<style>
.right-component {
  flex: 3; /* 右侧占3份 */
  padding: 20px;
  background-color: #e0e0e0;
}
.righttext{
    line-height: 30px;
    text-indent: 2em;
  }
  
  div h1{
    text-align: center;
  }
  .maintext{
    padding: 50px;
  }
</style>