import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router/index';
import axios from 'axios'

// 设置axios为Vue实例的原型属性
Vue.prototype.$axios = axios

Vue.config.productionTip=false
Vue.use(ElementUI);

new Vue({
  el: '#app',
  render: h => h(App),
  router:router
});