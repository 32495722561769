<template>
    <div class="rightleft">
      <h1>
        留言区
        <el-divider content-position="right" class="separator-line">留言</el-divider>
      </h1>   
      <div v-for="(message, index) in messages" :key="index" class="message">
        <el-card class="message-card">
          <p>{{ message.content }}</p>
          <p class="author">—— {{ message.author }}</p>
        </el-card>
      </div>
      <div style=" margin-top: auto;    text-align: right; ">
        <a class="bottomtext" @click=window>查看更多&留言>></a>
    </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        messages: [
          { content: '很高兴认识你，和你在一起的时光总是很愉快，往后也请多指教。(✪ω✪)', author: 'EMT' },
          { content: '好久没见面了，狗修金现在过得怎么样呢？可别忘了本座啊(▼ヘ▼#)', author: '丛雨' },
          { content: '你好陌生人。', author: '匿名' },
          { content: '你好久没登录SAO了吧，什么时候再一起开黑。', author: 'Asuna' },
        ],
      };
    },
    methods:{
      window(){
        this.$router.push(`/comments`)
        this.$nextTick(() => {
        window.scrollTo(0, 100);
      });
      }
    }
  };
  </script>
  
  <style scoped>
  .bottomtext{
    text-decoration: none;
    color: #4593ba;
  }
  .bottomtext:hover{
    cursor: pointer;
  }
  .rightleft {
    flex: 3;
    width: 100%;
    margin: 20px;
    padding: 20px;
    background-color: #eff3f5;
    background-repeat: no-repeat;
    background-size: contain;
    border: 2.5px solid rgb(129, 185, 228);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  
  div h1 {
    line-height: 15px;
    font-weight: 400;
    color: #283f74;
    font-family: "隶书";
  }
  
  .separator-line {
    background-color: rgb(129, 185, 228);
    margin: 20px 0;
    border: 1.5px solid rgb(129, 185, 228);
  }
  
  .message {
    margin-bottom: 20px;
  }
  
  .message-card {
    border: 1px solid #b3c0d1;
    border-radius: 10px;
  }
  
  .message p {
    margin: 0;
  }
  
  .author {
    font-style: italic;
    color: #555;
    text-align: right;
  }
  </style>
  