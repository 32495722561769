<template>
    <div class="artright">
      <el-page-header @back="goBack" content="详情页面" >
</el-page-header >
    <!-- 右侧内容 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'RightComponent',
  methods:{
    goBack(){
    this.$router.push('/main');
  }
  }
};
</script>

<style>
.artright {
  flex: 3; /* 右侧占3份 */
  padding: 20px;
  background-color: #e0e0e0;
}
div.maintext p{
    line-height: 30px;
    text-indent: 2em;
}

div h1{
  text-align: center;
}
.maintext{
  padding: 50px;
}
</style>
