<!-- App.vue -->
<template>
    <div id="tp">
      <ToolLeft></ToolLeft>
      <ToolRight></ToolRight>  
    </div>
</template>

<script>
import ToolLeft from '@/components/ToolLeft.vue';
import ToolRight from '@/components/ToolRight.vue';

export default {
  name:'MyArt',
  components: {
    ToolLeft ,
    ToolRight,
  },
};
</script>

<style scoped>
#tp {
  display: flex; 
  height: 93vh; 
  background-color: #eff3f5;
}
</style>
