<template>
  <div class="right-component">
    <h1>网站设计与开发实验报告</h1>
        <div class="maintext" v-show="this.index===1">
          <h3>一、实验目的和要求</h3> 
          <br>
            <h4>目的：</h4><p>
            <ul>
              <li>1.	掌握并运用HTML以及CSS技术。</li>
              <li>2.	掌握并能灵活运用Vue技术开发动态网站。</li>
              <li>3.	能够结合所学知识设计与开发个人网站。</li>
            </ul></p>
            <br>
            <h4>要求：</h4><p>
            <ul>
              <li>1.	网站需要由几个栏目组成；</li>
              <li>2.	网站中需要出现您的个人信息；</li>
              <li>3.	网站必须用了VUE技术，必须有一定的动态性；</li>
            </ul></p>
            <br><br><br><br><br> 
        </div>
        <div :class="[maintext]" v-show="this.index===2" >
          <br>
          <h3>二、实验内容（例如实验步骤、代码）</h3><br> 
          <h4>实验步骤：</h4><br>
          <h5>1、设计网站的布局排版。</h5>
          <p class="righttext"> 包括这个网站有几个栏目，首页要如何排版，有哪些复用组件。
            如图所示，我一共设计了四个栏目，分别是首页、文章作品、留言区、工具箱以及个人动态。除此之外，我还设置了起始页
          </p> <br>
          <h5>2、设置网站的第一层路由以及复用组件 。</h5>
          <p class="righttext">
            首先先简单浏览一下第一次路由的保留的组件，分别是项目栏，title部分，和顶部bottom组件。<br>
            Title部分采用了背景图和背景颜色组合的方式融为一体，并在左侧加上title：welcome my web。<br>
            右侧是个搜索框和三个超链接，其中两个可以跳转到学校和学院官网，右侧搜索框可以跳转到输入的路径名称，比如我要到文章页面，之间输入articles或者再加上具体的文章名可以直接跳转。<br>
            对于项目栏，我用来position的sticky的属性，使他能在页面滚动时保留在顶部，并且设置了z-index的值，使它能一直处于最上层。<br>
            除此之外，我还设置了window.scrollTo(0, 100);的方法，每次点击可以回到页面中央。<br>
            以上三个组件均在App.vue文件，下图是temple中的排列方式。<br>
            Class App中放的是router-view模块，
            用来引入中间页面
          </p> <br>
        </div>
        <div class="maintext" v-show="this.index===3"><br>
          <h5>3、网站首页</h5>
          <p class="righttext">
              对于首页的设计，我选择了flex布局，左右1:3排列，其中左侧分为上、下两块，右侧在分成上、下左、下右三块。</p>  
              <p>教育经历模块中，我放置了一个滚动图的组件，每隔1500毫秒自动切换图片，通过点击左右两个箭头，或者鼠标hover到小圆点处，也可自行切换图片 
              图片嵌套在了a标签下，点击可以跳转到学校官网。</p> 
              <p>除此之外，图片还设置了mouseenter和mouseleave，用来暂停和恢复图片的轮播。</p>  
              <p>左下对文章作品进行简单的排布，每个文章或者右上角查看更多点击后可以跳转到相应的地方。</p>  
              <p>对于中间的留言区，我用了v-for进行渲染data中的数据右下角的查看更多，点击可以跳转到文章作品栏目中。</p>  
              <p>对于联系方式模块，通过鼠标hover的方式，可以展现不同的联系方式。首先用mouseenter来切换tab，并有两个方法来改变背景颜色和index的值。</p>  
               <br><br><br><br><br>
        </div>
        <el-pagination
        hide-on-single-page
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-size="1"
        layout="prev, pager, next"
        :total="3"
        style="margin-top: auto;    text-align: center;position: relative; bottom: 6%;">
        </el-pagination>
  </div>
</template>

<script>
export default {
  name:'ArtFirst',
  data() {
  return {
    index:1
  };
},
  methods: {
    handleCurrentChange(val) {
      this.index=val
    }
  },
}
</script>

<style scoped>


</style>