<template>
  <div class="calendar">
    <el-calendar v-model="value" style="height: 630px;">
    </el-calendar>
  </div>
</template>

<script>
export default {
    name:'ToolFirst',
    data() {
      return {
        value: new Date()
      }
    }

}
</script>

<style>
.calendar{
    margin: 12px;
}
</style>