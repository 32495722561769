<template>
  <div id="app">
    <ArtLeft></ArtLeft>
    <ArtRight></ArtRight>  
  </div>
</template>

<script>
import ArtLeft from '@/components/ArtLeft.vue';
import ArtRight from '@/components/ArtRight.vue';

export default {
  name:'MyArt',
  components: {
    ArtLeft ,
    ArtRight,
  },
};
</script>

<style>
#app {
  display: flex; 
  height: 100vh; 
}
</style>
