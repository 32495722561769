<template>
    <div class="left-section">
      <div style="align-self: flex-start ;">
        <br>
        <h2>&nbsp;&nbsp;教育经历：</h2>
        <br>
      </div>
      <slider></slider>
      <br>
      <el-divider content-position="right" class="separator-line">学历信息</el-divider>
      <div style="align-self: flex-start ;">
        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
          <br><br>
        <h2>&nbsp;&nbsp;文章&作品：</h2>
        <a @click=go  class="rush" style="position: absolute;left: 28%;color:#4593ba ;">查看更多 >></a>
      </div>
        <br>
        <br>
        <h3>&nbsp;&nbsp;&nbsp;&nbsp;2024:</h3>
        <br>
        <a href="/articles/01">>个人简介</a><br>
        <a href="/articles/02">>网站设计与开发实验报告</a><br>
        <a href="/articles/03">>认识实习报告</a><br>

        <br>
        <h3>&nbsp;&nbsp;&nbsp;&nbsp;2023:</h3>
        <br>
        <a href="/articles/04">>基于openCV的人脸识别系统</a><br>
        <a href="/articles/05">>基于几种基础排序的最优排序方法</a><br>
        <a href="/articles/06">>关于机器学习建模步骤</a><br>
        <a href="/articles/07">>机器学习的评价指标</a><br>
        <a href="/articles/08">>机器学习双创</a><br>
        <br>
        <h3>&nbsp;&nbsp;&nbsp;&nbsp;2022:</h3>
        <br>
        <a href="/articles/09">>高考成绩单</a><br>
        <a href="/articles/10">>以下是重复内容</a><br>
        <a href="#">>关于机器学习建模步骤</a><br>
        <a href="#">>机器学习的评价指标</a><br>
        <a href="#">>机器学习双创</a><br>
        <br>
        <h3>&nbsp;&nbsp;&nbsp;&nbsp;2021:</h3>
        <br>
        <a href="#">>关于机器学习建模步骤</a><br>
        <a href="#">>机器学习的评价指标</a><br>
        <a href="#">>机器学习双创</a><br>
        <br>
      </div>
    </div>
  </template>
  
  <script>
  import slider from './SliderPic.vue';
  export default {
    components: {
      slider,
    },
    methods:{
      go(){
        this.$router.push(`/articles/01`)
        this.$nextTick(() => {
        window.scrollTo(0, 100);
      });
      }
    }
    
  }
  </script>
  
<style scoped>
.rush{
  margin-right: 100px;
  font-size: 18px; 
  color: #4593ba; 
  text-decoration: none;
  position: absolute;
  left: 52%;
}
.separator-line {
background-color: rgb(129, 185, 228); 
margin: 20px 0; 
border: 1.5px solid rgb(129, 185, 228);
}

div.left-section div h2{
  line-height: 15px;
  font-weight: 400;
  color: #283f74;
}
div.left-section div h3{
  line-height: 30px;
  font-weight: 400;
  font-size: 30px;
  color: #326092;
}
.left-section {
  height: 1400px;
  width: 100%;
  margin: 20px;
  flex: 2; /* 占据左侧的1/3宽度 */
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1.5px solid rgb(129, 185, 228);
  border-radius: 10px;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}
div.left-section a{
  position: relative; /* 使用相对定位 */
  left: 45px;
  font-size: 20px;
  color: rgb(122, 168, 231);
  text-decoration: none;
  line-height: 30px;
}
div.left-section a:hover{
  text-decoration: underline;
  cursor: pointer;
}
</style>

