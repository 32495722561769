<template>
  <div class="flex-container">
    <!-- 左侧表单 -->
    <div class="left">
      <div class="block">
        <el-avatar icon="el-icon-user-solid" :size="80" :src="myImg"></el-avatar>
      </div>
      <div class="lefttext">*编辑你的留言信息：</div>
      <el-form  label-width="80px">
        <el-form-item label="是否匿名">
          <el-switch v-model="hide"></el-switch>
        </el-form-item>
        <div v-if="!hide">
          <label for="nickname">名称：</label>
        <el-input v-model="nickname" placeholder="请输入你的昵称" type="text" required></el-input>
        <br><br>
        <label for="gender">头像：</label>
        <br>
        <el-select v-model="myImg" placeholder="选择你的头像" id="gender">
          <el-option value='' label="默认">&nbsp;&nbsp;默认</el-option>
          <el-option value="./head/asuna.png" label="亚丝娜">&nbsp;&nbsp;亚丝娜</el-option>
          <el-option value="./head/congyu.png" label="丛雨">&nbsp;&nbsp;丛雨</el-option>
          <el-option value="./head/emt.png" label="艾米莉亚">&nbsp;&nbsp;艾米莉亚</el-option>
          <el-option value="./head/mozi.png" label="常陆茉子">&nbsp;&nbsp;常陆茉子</el-option>
          <el-option value="./head/deng.png" label="高松灯">&nbsp;&nbsp;高松灯</el-option>
          <el-option value="./head/sensei.png" label="sensei">&nbsp;&nbsp;sensei</el-option>
          <el-option value="./head/baizi.png" label="白子">&nbsp;&nbsp;白子</el-option>
        </el-select>
        <br>
        <br>
        <label for="gender">性别：</label>
        <br>
        <el-select v-model="gender" placeholder="选择你的性别" id="gender">
          <el-option value="male" label="男"><i class="el-icon-male"></i>&nbsp;&nbsp;男</el-option>
          <el-option value="female" label="女"><i class="el-icon-female"></i>&nbsp;&nbsp;女</el-option>
        </el-select>
        <br>

        </div>
    </el-form>
    </div>

    <!-- 右侧评论区 -->
    <div class="right">
      <!-- 评论显示区 -->
      <div class="comment-section">
        <div v-for="(comment, index) in reversList" :key="index" class="comment-bubble" :class="{ 'male': comment.gender === 'male', 'female': comment.gender === 'female', 'unknown': comment.gender === 'unknown'}">
          <el-avatar icon="el-icon-user-solid" :src="comment.img" ></el-avatar>
          <div class="comment-content">
            <div>
              <strong>{{ comment.name }}:</strong>
              <span class="comment-date">{{ formatDate(comment.date) }}</span>
            </div>
             {{ comment.text }}
          </div>
        </div>
      </div>

      <!-- 发表评论区 -->
      <div class="comment-input">
        <div class="input-container">
          <textarea v-model="newComment" :maxlength="maxCommentLength" placeholder="发表留言"></textarea>
          <button @click="publishComment" :disabled="newComment.trim() === '' || remainingChars < 0" class="publish-button">发布</button>
        </div>
        <div class="comment-footer">
          <span :style="{ color: remainingChars < 0 ? 'red' : '#888' }">{{ remainingChars }} 字剩余</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MC',
  data() {
    return {
      myImg:'',
      hide:false,
      nickname: '',
      gender: 'male', // 默认性别为男性
      newComment: '',
      comments: [
        { name: 'EMT', gender: 'female', text: '很高兴认识你，和你在一起的时光总是很愉快，往后也请多指教。(✪ω✪)', date: '2023-11-06',img:'./head/emt.png' },
        { name: '丛雨', gender: 'female', text: '好久没见面了，狗修金现在过得怎么样呢？可别忘了本座啊(▼ヘ▼#)', date: '2023-12-05',img:'./head/congyu.png' },
        { name: 'Asuna', gender: 'female', text: '你好久没登录SAO了吧，什么时候再一起开黑。', date: '2020-06-05',img:'./head/asuna.png' },
        { name: '作者', gender: 'male', text: '大家好，目前留言区仅有演示功能（租不起服务器），需要留言可联系我打开后端。', date: '2099-12-31',img:'./head/sensei.png' },
        // 添加其他初始评论数据
      ],
      maxCommentLength: 200,
    };
  },
  computed: {
    remainingChars() {
      return this.maxCommentLength - this.newComment.length;
    },
    reversList(){
      return this.comments.slice().reverse();
    }
  },
  created(){
    this.load()
  },
  methods: {
    publishComment() {
      if (this.newComment.trim() !== '') {
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
        this.comments.unshift({
          name: this.nickname,
          gender: this.gender,
          text: this.newComment,
          date: formattedDate, // 添加发布日期
          img:this.myImg,
        });
        this.newComment = '';
      }
  },
  formatDate(dateString) {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  },
  },
  updated(){
    if(this.hide===true){
      this.nickname="匿名";
      this.gender= "unknown";
      this.myImg='';
    }
  },
}
</script>
  
<style scoped>
.form{
  margin: 20px;
}
.lefttext{
  margin: 10px;
  padding: 10px;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #4577a0;
}
.block{
  margin: auto;
  width: 50%;
  padding: 20px;
}
.flex-container {
  display: flex;
  max-width: 100%;
  height: 750px; 
  margin: auto;
  background-color: #e8edef
}

.left {
  flex: 1;
  padding: 30px;
  background-color: #ffffff;
  margin: 20px;
  border-radius: 15px;
}

.right {
  flex: 3;
  padding: 30px;
  background-color: #f8fafc;
  overflow-y: auto; /* 垂直滚动 */
  border: 1.5px ;
  border-radius: 15px;
  margin: 20px;
}

.comment-section {
  margin-bottom: 20px;
  position: relative;
  left: 6%;
  width: 90%;
}

.comment-bubble {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  /* border: 1.5px solid #ccc; */
  border-radius: 15px;
  margin: 25px;
  background-color: #eff3f5;
  box-shadow: 3px -3px 8px rgba(48, 52, 65, 0.2); /* 添加底部投影效果 */
}

.comment-bubble el-avatar {
  margin-right: 10px;
}

.comment-content {
  flex: 1;
  padding: 1%;
}

.comment-input {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 29.5%;
  width: 65%;
  padding: 20px; 
  margin: 30px;
  box-shadow: 0 -2px 5px rgba(48, 52, 65, 0.1); /* 添加底部投影效果 */
  border-radius: 15px;
  background-color: rgba(200, 234, 245, 0.3);
  height: 170px;
}

.input-container {
  display: flex;
  margin-bottom: 10px;
  height: 100%;

}

.comment-input textarea {
  flex: 1;
  resize: none;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 20px;
}

.comment-input button {
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  width: 15%;
}
.comment-date {
  font-size: 12px;
  color: #666;
  margin-left: 10px;
}
.comment-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comment-footer span {
  color: #888;
  font-size: 14px;
}

.comment-footer button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.comment-footer button:hover:disabled {
  background-color: #ccc;
}

.comment-footer button:hover {
  background-color: #45a049;
}
.comment-input button.publish-button {
padding: 10px;
background-color: #4caf50;
color: #fff;
border: none;
border-radius: 5px;
cursor: pointer;
outline: none;
transition: background-color 0.3s;
}

.comment-input button.publish-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.comment-input button.publish-button:hover:disabled {
  background-color: #ccc;
}

.comment-input button.publish-button:hover {
  background-color: #45a049;
}
.male {
  background-color: rgb(210, 237, 251,0.7); /* 男性评论气泡背景色 */
}

.female {
  background-color: rgb(250, 219, 218,0.7); /* 女性评论气泡背景色 */
}
.unknown {
  background-color: rgba(224, 224, 224, 0.7); /* 女性评论气泡背景色 */
}

</style>
  