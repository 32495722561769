<template>
    <div></div>
  </template>
  
  <script>
  export default {
      name:'ToolFirst',
  }
  </script>
  
  <style>
  
  </style>