<template>
  <div class="DT">
    <br>
    <div>
    <h3>&nbsp;&nbsp;我的游历：</h3>
    <el-carousel :interval="4000" type="card" height="400px">
        <div v-for="slide in sliderData" :key="slide.url">
            <el-carousel-item  >
            <img :src="slide.url" alt="" class="img" />
            </el-carousel-item> 
        </div>
    </el-carousel>
    </div>
    <el-divider content-position="right" class="separator-line">活动</el-divider>
    <br><br><br>
        <div class= "mid">
            <div class="center">更多内容，敬请期待</div>
        </div>
        
 

  </div>
</template>

<script>
export default {
    data(){
    return {
      sliderData: [
      { url: './dy_img/01.png',title:'合肥步行街'},
      { url: './dy_img/02.png',title:'翡翠湖'},
      { url: './dy_img/03.png',title:'苏州盘门'},
      { url: './dy_img/04.png',title:'苏州盘门'},
      { url: './dy_img/05.png',title:'苏州园林'},
      { url: './dy_img/06.png',title:'七彩丹霞'},
      ],
    };
  },
}
</script>

<style scoped>
.mid{
    width: 100%;
    height: 300px;
    background-color: #4f82bb;
}
.DT{
    margin: 30px;
    border-radius: 20px;
    background-color: #ffffff;
    flex: 1;
}
.center{
    text-align: center;
    line-height: 300px;
    font-size: 80px;
    font-family: '华文行楷';
}
.separator-line {
background-color: rgb(129, 185, 228); 
margin: 20px 0; 
border: 1.5px solid rgb(129, 185, 228);
}
  .img{
    width: 100%;
    height: 100%;
  }
  div.DT h3{
    color: #4f82bb;
    font-family: '方正舒体';
    font-size: larger;
  }
</style>