<!-- RightSection.vue -->
<template>
    <div class="right-section">
      <!-- 右侧部分内容 -->
      <div>
        <br>
        <h1>个人简介</h1>
        <div class="maintext" >
          <p style="text-indent: 0;"> 未知的陌生人，你好!</p>        
          <p class="righttext" >欢迎来到我的个人主页.如果你想了解我，不妨听我娓娓道来
          </p>
          <p class="righttext">我来自江苏省苏州市，姑苏区，今年刚满二十，就读于合肥工业大学软件工程专业。是个充满好奇心、对技术充满热情的年轻人。生长在姑苏区的我，这个历史悠久而又现代化的地方，深深地影响了我的成长。

在合肥工业大学软件工程专业的学习过程中，我不仅培养了坚实的编程基础，还接触了前沿的技术，例如人工智能、大数据和云计算。这些课程不仅拓宽了我的视野，也激发了我对技术创新的渴望。

我热衷于参与各种项目，从小规模的个人项目到大型团队协作。通过这些实践，我不仅学到了更多的技术知识，还培养了解决问题和团队协作的能力。

我的个人网站是我展示自己、分享心得和交流经验的平台，也是我的个人主页是一个我与你分享我的故事、学术旅程和一些技术趣味的地方。你可以在这里找到我的技术博客、学术心得，甚至是一些我在生活中的所思所想。同时，我也鼓励互动，如果你有任何问题或建议，都欢迎在留言板留言，我们一起讨论，一起进步。

除了技术之外，我还喜欢运动、摄影和旅行。在空闲时间，我会挑战一些户外活动，拍摄一些美丽的瞬间，或者规划下一次旅途。生活中的点滴都是我前进的动力。

感谢你访问我的个人主页，希望通过这个平台，我们能够互相启发，共同成长。期待在这个数字世界中，结识更多志同道合的朋友！

谢谢，期待我们的交流！

          </p>
          <br>
          <br>
          <p style="text-align: right;">2024年1月1日</p>
          <br>
          <el-divider content-position="right" class="separator-line">个人介绍</el-divider>
        </div>
      
      </div>
      <div>
        <RightBottom></RightBottom>
      </div>

    </div>
  </template>
    <script>
    import RightBottom from './RightBottom.vue';
    export default {
      components: {
        RightBottom,
    }
    }
    </script>



  <style scoped>
  .separator-line {
    background-color: rgb(129, 185, 228); /* 设置线的颜色 */
    margin: 20px 0; /* 设置线与组件之间的间距 */
    border: 1.5px solid rgb(129, 185, 228);

    }
  .right-section {
    flex: 3; /* 占据右侧的2/3宽度 */
    height: 1400px;
    width: 100%;
    margin: 20px;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1.5px solid rgb(129, 185, 228);
    border-radius: 10px;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;

  }
  .righttext{
    line-height: 30px;
    text-indent: 2em;
  }
  
  div h1{
    text-align: center;
  }
  .maintext{
    padding: 50px;
  }
  </style>
  