import { render, staticRenderFns } from "./RightSection.vue?vue&type=template&id=553d3b7e&scoped=true"
import script from "./RightSection.vue?vue&type=script&lang=js"
export * from "./RightSection.vue?vue&type=script&lang=js"
import style0 from "./RightSection.vue?vue&type=style&index=0&id=553d3b7e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "553d3b7e",
  null
  
)

export default component.exports