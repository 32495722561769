<template>
    <div class="body">
        <LeftSection />
        <RightSection />
    </div>
</template>

<script>
import LeftSection from '@/components/LeftSection.vue';
import RightSection from '@/components/RightSection.vue';
export default {
    components: {
        LeftSection,
        RightSection,
    }
}
</script>

<style scoped>
.body{
    display: flex;
    flex-direction: row;
    background-color: #f2f6fa;
}

</style>