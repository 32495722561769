<template>
    <div class="tab">
      <div class="tab-nav">
        <h3>联系我</h3>
        <ul>
          <li v-for="(link, index) in links" :key="index">
            <a :class="{ 'active': index === currentIndex }" @mouseenter="changeTab(index)" href="javascript:;">{{ link }}</a>
          </li>
        </ul>
      </div>
  <div class="tab-content">
    <div v-for="(content, index) in contents" :key="index" class="item" :class="{ 'active': index === currentIndex }">
      <div :style="{ 'background-color': getBackgroundColor(index) }" class="custom-content">
        <div class="content-wrapper">
          <img src="@/assets/5.png" alt="" class="img2">
          <div class="text-wrapper">
            <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ content.title }}</h4>
          </div>
        </div>
        <img v-if="content.image" :src="require(`@/assets/tap_images/${index + 1}.png`)" alt="" class="img1">
      </div>
    </div>
  </div>
    </div>
</template>
  
<script>
  export default {
    name:'TapTap',
    data() {
      return {
        currentIndex: 0,
        links: ['微信', 'QQ', '邮箱', '赞助'],
        contents: [
          { title: '加我微信', image: './tap_images/1.png' },
          { title: '加我QQ', image: './tap_images/1.png' },
          { title: '邮箱联系我', image: './tap_images/1.png' },
          { title: '赞助一下吧', image: './tap_images/1.png' },
        ],
        backgroundColors: ['#fff', '#fff', 'rgb(243,243,245)', 'rgb(0,194,95)']
      };
    },
    methods: {
      changeTab(index) {
        this.currentIndex = index;
      },
      getBackgroundColor(index) {
        return this.currentIndex === index ? this.backgroundColors[index] : '';
      }
    }
  };
</script>
  
<style>
.content-wrapper {
    display: flex;
    align-items: right;
}
.text-wrapper {
  font-family: '华文彩云';
  flex: 1; 
  display: flex;
  align-items: center; /* 垂直居中 */
  font-size: 25px;
}
.custom-content {
    height: 550px;
    padding: 0px;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* 隐藏溢出部分 */
}
.img1 {
  max-width: 100%; /* 图像的最大宽度为其容器的宽度 */
  max-height: 100%; /* 图像的最大高度为其容器的高度 */
  display: block; /* 防止图像底部出现间距 */
  margin: auto; /* 图像居中显示 */
}
.img2 {
  max-width: 30%; /* 图像的最大宽度为其容器的宽度 */
  display: block; /* 防止图像底部出现间距 */
  margin: left top ; /* 图像居中显示 */
}

* {
  margin: 0;
  padding: 0;
}
.tab {
  width: 370px;
  height: 640px;
  margin: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 15px;
}
.tab-nav {
  width: 100%;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}
.tab-nav h3 {
  font-size: 24px;
  font-weight: normal;
  margin-left: 20px;
}
.tab-nav ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
}
.tab-nav ul li {
  margin: 0 20px;
  font-size: 14px;
}
.tab-nav ul li a {
  text-decoration: none;
  border-bottom: 2px solid transparent;
  color: #333;
}
.tab-nav ul li a.active {
  border-color: #e1251b;
  color: #e1251b;
}
.tab-content {
  padding: 0 16px;
}
.tab-content .item {
  display: none;
}
.tab-content .item.active {
  display: block;
}
  
</style>
