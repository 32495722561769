<template>
  <div class="right-component">
    <h1>个人简介</h1>
        <div class="maintext" v-show="this.index===1">
          <h3>一、实验目的和要求</h3>       
          <br>
            <h5>本次认识实习，主要对世源科技，科大讯飞两家企业进行考察学习。本次认识实习的主要目的有：</h5><br>
            <p>
            <ul>
              <li>1.	了解不同的企业文化以及企业价值观</li>
              <li>2.	了解不同行业的业务运作流程。包括项目管理、团队协作、产品研发和测试等方面。</li>
              <li>3.	实践个人兴趣。在实习过程中，了解不同领域的工作，从而更好地了解个人的兴趣和职业发展方向。</li>
            </ul></p>
            <br>
            <h3>二、实习时间和地点</h3>   <br>
            <p>2023年12月9日 16时-19时：  科大讯飞公司；</p>
            <p>2023年12月12日 14时-17时： 视源电子科技公司。</p><br><br><br>
        </div>
        <div class="maintext" v-show="this.index===2">
          <h3>三、实习经过</h3> <br>
          <h4>科大讯飞公司：</h4>
          <p>进入公司内部，在讲解员的带领下，我们首先体验了讯飞语音技术。通过仿真人的演示，我们感受到了其在真实感和表达力方面的出色表现。经过讲解员的介绍，我们了解了声音合成背后各种技术，包括语音合成技术，图像合成技术，多模态联动等深度学习算法和模型。随后，我们又体验了讯飞的实况语音识别技术。在实况语音识别技术展示中，我们了解了科大讯飞公司在语音识别领域的研究成果。
            通过实际演示，我们看到了实时语音识别系统的高准确性和快速响应。这项技术在语音助手、语音搜索等领域有广泛的应用，为我们展示了科大讯飞在人工智能语音领域的领先地位。在产品方面，讲解员又向我们介绍了讯飞会议耳机技术，他们展示了会议耳机，即能在会议中实时将会议内容转化为文字，提供实时翻译和记录功能。除此之外，这种耳机还能提供助听器的功能，能够精准的对语音文字进行放大，
            对环境声等其他杂音进行弱化，主要用的是降噪技术。我们还有幸参观了科大讯飞公司的声学成像仪，了解了其在声学成像和空间声学方面的研究。这项技术对于环境声音的定位和识别有着重要的应用，包括在设备故障检修、无人驾驶、安防监控等领域。主要用了麦克风阵列。声学成像技术。在教育方面，讯飞推出了智能评卷系统，它通过OCR识别技术，能够对试卷进行智能识别，进而实现对试卷批改，
            并能立刻得出成绩。最后我们体验了讯飞星火大模型。在体验中，我们深入了解了大规模深度学习模型的研究和应用。通过讲解员的演示，我们了解到这些大模型在自然语言处理、图像识别等领域有着卓越的性能，体验到了科大讯飞在人工智能领域的创新，在人工智能领域处在前沿水平。</p>
        </div>
        <div class="maintext" v-show="this.index===3">
          <h4>视源电子科技：</h4>
          <p>走进视源电子科技公司，在讲解员带领下，我们先参观了两种希沃教室。对于第一种希沃教室，其核心为学生的视听健康服务。这是世源科技在教育领域的创新产品。希沃教室融合了各种视觉和听觉科技，提供智能化的教育解决方案。除此之外，还有智能录播系统，能够自行对教学内容进行全方位录制。对于第二种希沃教室，其智能化的讲台、电子屏等，让我们在体验过程中，
            感受到了先进的互动性、自适应学习和智能辅助教育的特点。这些技术为未来教育提供了全新的可能性。</p>
          <p>在进入CVTE企业文化区中我们详细了解了世源科技公司的各种文化，其中突出的是“第一品牌：希沃”的理念。公司强调在全球范围内建立第一品牌的愿景，并在国际市场上取得显著成绩。此外，我们了解到公司在四大板块上的布局，包括显示技术、生活板块、未来教育和企业服务。在参观中，我们了解到世源科技公司在技术创新方面的重要成果之一是其专利积累。公司注重知识产权的保护，
            通过持续的研发和创新，不断积累专利，巩固了在行业中的竞争力。世源科技公司还展示了其在社会责任方面的努力。通过参与公益事业、环保活动等方式，公司积极履行社会责任，展示了企业对社会的关注和承担。</p><br><br><br><br><br><br>
        </div>
        <div class="maintext" v-show="this.index===4">
          <p>
            在产品参观区，我们先参观了希沃的教学程序，这是一种专注于教育领域的软件解决方案。该程序包括教学资源管理、在线课程设计、学生评估等功能，支持教育机构提供更高效的教学服务。除此之外还有希沃的智慧黑板。它是一种融合了互联网和教育技术的智能教学工具。它具有触摸屏、互动性、多媒体播放等功能，为教师和学生提供了更灵活、更丰富的教学体验。随后我们又参观了MAXHUB的产品，
            有LED显示屏和会议屏。MAXHUB的LED显示屏是一种高清晰度、高亮度的显示屏。该产品在会议和演示场合中应用广泛，提供清晰的图像和多点触控功能，增强了会议效果。 MAXHUB的会议屏是专为企业和团队协作设计的设备。它支持多人同时操作，实现了实时共享和互动，提升了会议效率和沟通效果。
            在数字医疗产品方面。我们了解了世源科技公司的数字影像解决方案。主要有医疗影像设备和软件，用于实现数字化的医疗影像管理和诊断。我们还参观了生活电器和智能健身产品，了解了世源科技公司在这两个领域的创新产品。生活电器涵盖了智能家居、家电控制等方面，而智能健身产品则结合了科技和健康，提供了更智能、个性化的健身解决方案。
          </p><br><br><br><br><br><br><br>
        </div>
        <el-pagination
        hide-on-single-page
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-size="1"
        layout="prev, pager, next"
        :total="4"
        style="margin-top: auto;    text-align: center;position: relative; bottom: 6%;">
        </el-pagination>
  </div>
</template>

<script>
export default {
    name:'ArtFirst',
    data() {
    return {
    index:1
  };
},
methods: {
    handleCurrentChange(val) {
      this.index=val
    }
  },
}
</script>

<style scoped>

</style>