<template>
  <div class="slider" ref="sliderContainer" @mouseenter="pauseSlider" @mouseleave="resumeSlider">
    <div class="slider-wrapper">
        <a v-for="(slide, index) in sliderData" :key="index" :href="slide.link" target="_blank">
          <img v-show ="i === index" ref="sliderImage" :src="slide.url" :alt="slide.title" />
        </a>
    </div>
    <div class="slider-footer" ref="sliderColor">
      <p ref="sliderTitle"></p>
      <ul class="slider-indicator">
        <li v-for="(slide, index) in sliderData" :key="index" @mouseenter="selectSlide(index)" :class="{ 'active': i === index }"></li>
      </ul>
      <div class="toggle">
        <button @click="reduce" class="prev">&lt;</button>
        <button @click="add" class="next">></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sliderData: [
      { url: './slider_images/slider01.jpg', title: '苏州市带城实验小学校', color: 'rgb(151, 180, 201)' ,link: 'https://baike.baidu.com/item/%E8%8B%8F%E5%B7%9E%E5%B8%82%E5%B8%A6%E5%9F%8E%E5%AE%9E%E9%AA%8C%E5%B0%8F%E5%AD%A6%E6%A0%A1/55799061'},
      { url: './slider_images/slider02.jpg', title: '苏州市振华中学', color: 'rgb(43, 35, 26)' ,link: 'http://zhzx.suzhou.edu.cn/'},
      { url: './slider_images/slider03.jpg', title: '苏州大学附属中学', color: 'rgb(170, 68, 68)' ,link: 'https://szsdfz.sipedu.org/'},
      { url: './slider_images/slider04.jpg', title: '合肥工业大学', color: 'rgb(169, 73, 58)',link: 'https://www.hfut.edu.cn/' },
      { url: './slider_images/slider05.jpg', title: '合肥工业大学软件学院', color: 'rgb(67, 90, 92)' ,link: 'https://rjxy.hfut.edu.cn/'},
      
      ],
      i: 0, // 信号量 控制图片的张数
    };
  },
  methods: {
    add() {
      // 使用 setTimeout 重新设置延时
      this.i++;
      this.updateSlider();
    },
    reduce() {
      this.i--;
      this.updateSlider();
    },
    updateSlider() {
      const img = this.$refs.sliderImage;
      const p = this.$refs.sliderTitle;
      const footer = this.$refs.sliderColor;

      if (this.i >= this.sliderData.length) {
        this.i = 0;
      } else if (this.i < 0) {
        this.i = this.sliderData.length - 1;
      }

      img.src = this.sliderData[this.i].url;
      p.innerHTML = this.sliderData[this.i].title;
      footer.style.backgroundColor = this.sliderData[this.i].color;

      // 小圆点
      const activeIndicator = this.$refs.sliderContainer.querySelector('.slider-indicator .active');
      activeIndicator.classList.remove('active');
      const currentIndicator = this.$refs.sliderContainer.querySelector(`.slider-indicator li:nth-child(${this.i + 1})`);
      currentIndicator.classList.add('active');
      
    },
    pauseSlider() {
      clearInterval(this.sliderInterval);
    },

    resumeSlider() {
      // 当鼠标离开时重新设置定时器
      this.sliderInterval = setInterval(() => {
        this.i++;
        this.updateSlider();
      }, 1500);
    },
    selectSlide(index) {
      this.i = index;
      this.updateSlider();
  },
  },
  mounted() {
    this.$nextTick(() => {
    // 在这里访问 $refs
    const img = this.$refs.sliderImage;
    const p = this.$refs.sliderTitle;
    const footer = this.$refs.sliderColor;
    p.innerHTML = this.sliderData[0].title;
    img.src = this.sliderData[0].url;
    footer.style.backgroundColor = this.sliderData[0].color;

    // 初始化间隔并将其存储在属性中
    this.sliderInterval = setInterval(() => {
      this.i++;
      this.updateSlider();
    }, 1500);
  });


  },
  beforeDestroy() {
  clearInterval(this.sliderInterval);
}
};
</script>

<style>
* {
    box-sizing: border-box;
  }

.slider {
    width: 560px;
    height: 400px;
    overflow: hidden;
}

.slider-wrapper {
  width: 100%;
  height: 320px;
}
.slider-wrapper img {
  width: 100%;
  height: 100%;
  display: block;
}
.slider-wrapper img:hover {
    cursor: pointer; 
}
.slider-footer {
  height: 80px;
  background-color: rgb(100, 67, 68);
  padding: 12px 12px 0 12px;
  position: relative;
}
.slider-footer .toggle {
  position: absolute;
  right: 0;
  top: 12px;
  display: flex;
}
.slider-footer .toggle button {
  margin-right: 12px;
  width: 28px;
  height: 28px;
  appearance: none;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}
.slider-footer .toggle button:hover {
  background: rgba(255, 255, 255, 0.2);
}
.slider-footer p {
  margin: 0;
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
}
.slider-indicator {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}
.slider-indicator li {
  width: 8px;
  height: 8px;
  margin: 4px;
  border-radius: 50%;
  background: #fff;
  opacity: 0.4;
  cursor: pointer;
}
.slider-indicator li.active {
  width: 12px;
  height: 12px;
  opacity: 1;
}
</style>
