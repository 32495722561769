<template>
  <div class="container">
    <div class="layouts">
      <div class="left">
      <div class="photo">
        <img class="image" src="@/assets/head.png" alt="">
      </div>
      <h1>Mr Lu</h1>
      </div>
    <div class="right">
      <h2>Hello, Welcome</h2>
      <h1>I'm LuJialiang</h1>
      <p>这里是陆嘉良的官网,
        <br>
        <br>在这里你可以访问我的个人信息，
        <br>包括我的联系方式、教育经历、文章发表、生活状态等等，
        <br>你也可以向我留言
        <br>
        <br>
        <el-button type="primary" round 
        style="position: absolute; right: 20px;" @click="go">进入主页</el-button>
      </p>
    </div>
    </div>
  </div>
</template>

<script>
export default {
    name:'MyFirst',
    methods:{
      go(){
        this.$router.push('main')
        this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
      }
    },
    mounted() {
    this.$nextTick(() => {
      window.scrollTo(0, 100);
    });
  },
}
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
}
.container {
 
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
}
.layouts {
  display: flex;
}
.left {
  height: 1000px;
  flex: 1; /* 占据左侧的1/3宽度 */
  background-color: #000000;
  background-image: url('@/assets/left.png');
  background-repeat: no-repeat;
  background-position:left 0px;
  background-size: contain;
}
.right {
  height: 1000px;
  flex: 2; /* 占据右侧的2/3宽度 */
  background-color: #d1e1f8;
  text-align: right;
}
div.right h1{
  position: absolute; 
  right: 10px; 
  top: 40%;
  font-size: 60px;
}
div.right h2{
  position: absolute; 
  right: 16%; 
  top: 35%;
  color: rgb(245, 238, 224);
}
div.right p{
  position: absolute; 
  right: 20px; 
  top: 60%;
  font-size: 20px;
}
.photo{
  width: 18%;
  height: 35%;
  background-color: #1f428e;
  margin: 0;
  position: absolute;
  top: 30%;
  left: 10%;
}
.image{
  height: 100%;
  width: 100%;
  position: absolute;
  top: -10%;
  left: -10%;
  object-fit: cover;
}
div.left h1{
  color: rgb(194, 230, 250);
  position: absolute;
  top: 70%;
  left: 13.5%;
}

</style>