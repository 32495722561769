<!-- LeftComponent.vue -->
<template>
<div class="left-art" >
    <el-menu router style="margin: 15px;border-radius: 15px;"
      default-active="2"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose">
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-s-management"></i>
          <span>2024年</span>
        </template>
        <el-menu-item-group router>
          <el-menu-item index="/articles/01">个人简介</el-menu-item>
          <el-menu-item index="/articles/02">网站设计与开发实验报告</el-menu-item>
          <el-menu-item index="/articles/03">认识实习报告</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-s-unfold"></i>
          <span>2023年</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/articles/04">基于openCV的人脸识别系统</el-menu-item>
          <el-menu-item index="/articles/05">基于几种基础排序的最优排序方法</el-menu-item>
          <el-menu-item index="/articles/06">关于机器学习建模步骤</el-menu-item>
          <el-menu-item index="/articles/07">机器学习的评价指标</el-menu-item>
          <el-menu-item index="/articles/08">机器学习双创</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <i class="el-icon-s-promotion"></i>
          <span>2022年</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/articles/09">高考成绩单</el-menu-item>
          <el-menu-item index="/articles/10">我也不知道写什么了</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">
          <i class="el-icon-document-add"></i>
          <span>2021年</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="2-1">暂无</el-menu-item>
          <el-menu-item index="2-2">暂无</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="5">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span>2020年</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="#">暂无</el-menu-item>
          <el-menu-item index="#">暂无</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

    </el-menu>
</div>
</template>

<script>
export default {
  methods: {
  handleOpen(key, keyPath) {
    console.log(key, keyPath);
  },
  handleClose(key, keyPath) {
    console.log(key, keyPath);
  }
}
};
</script>

<style scoped>
.left-art {
  padding: 10px;
  background-color: #eff3f5;
  flex: 1; /* 左侧占1份 */
  overflow-y: auto; /* 允许垂直滚动 */
  max-height: 100%; /* 设置最大高度，超过最大高度时出现滚动条 */
  
}
</style>
