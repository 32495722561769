<template>
<div class="left-tool" >
    <el-menu router style="margin: 15px;border-radius: 15px;"
      default-active="2"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose">
      <el-menu-item index="/tools/01">
        <i class="el-icon-date"></i>
        <span slot="title">日历</span>
      </el-menu-item>
      <el-menu-item index="/tools/02">
        <i class="el-icon-time"></i>
        <span slot="title">计时器与倒计时</span>
      </el-menu-item>
      <el-menu-item index="/tools/03">
        <i class="el-icon-date"></i>
        <span slot="title">合肥工业大学校历</span>
      </el-menu-item>
      <el-menu-item index="/tools/04">
        <i class="el-icon-date"></i>
        <span slot="title">我的课表</span>
      </el-menu-item>
      <el-menu-item index="/tools/05">
        <i class="el-icon-menu"></i>
        <span slot="title">其他</span>
      </el-menu-item>

    </el-menu>
</div>
</template>
  
<script>
export default {
  methods: {
  handleOpen(key, keyPath) {
    console.log(key, keyPath);
  },
  handleClose(key, keyPath) {
    console.log(key, keyPath);
  }
}
};
</script>

<style scoped>
.left-tool {
  background-color: #eff3f5;
  flex: 1; /* 左侧占1份 */
  overflow-y: auto; /* 允许垂直滚动 */
  max-height: 100%; /* 设置最大高度，超过最大高度时出现滚动条 */
  margin: 10px;
  
}
</style>
