<template>
  <div>
    <div class="top">
      <div class="toptext">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Welcome my Web
        <a style="right: 23%;" class="link" @click="contact" >联系我</a>
        <a style="right: 26.5%;" class="l" >|</a>
        <a style="right: 27.5%;" class="link" href='https://www.hfut.edu.cn/' target="_blank">合肥工业大学</a>        
        <a style="right: 34%;" class="l">|</a>        
        <a style="right: 35%;" class="link" href='https://rjxy.hfut.edu.cn/' target="_blank">软件学院</a>
      </div>
      <input class="input" type="text" v-model="searchtext" placeholder="请输入路径名">
      <el-button @click="search" type="primary" icon="el-icon-search" style="  position: absolute;right: 1%;top: 28px;">搜索</el-button>
    </div>
    <div class="sticky-menu" @click="window">
    <el-menu :default-active=firstLevelPath v-show="this.$route.name!=='first'"  @select="handleSelect" background-color="#3C7DAE" active-text-color="#AEF3FF" text-color="#BCCFD5" class="el-menu-demo" mode="horizontal" router>
      <el-menu-item index="/main">&nbsp;&nbsp;首页&nbsp;&nbsp;</el-menu-item>  
          <el-submenu index="/articles">
            <template slot="title">文章作品</template>
            <el-menu-item index="/articles" v-show="false" >进入文章页</el-menu-item>
            <el-menu-item index="/articles/01">2024年</el-menu-item>
            <el-menu-item index="/articles/04">2023年</el-menu-item>
            <el-menu-item index="/articles/09">2022年</el-menu-item>
            <el-menu-item index="/articles/11">2021年</el-menu-item>
        </el-submenu>
      <el-menu-item index="/comments">给我留言</el-menu-item>  
      <el-menu-item index="/tools">工具箱</el-menu-item>
      <el-menu-item index="/dynamic">个人动态</el-menu-item>
    </el-menu>
  </div>
  <div class="App">
    <router-view>
    </router-view>
  </div>
  <div>
    <BottomSection></BottomSection>
  </div>
</div>
</template>

<script>
import BottomSection from '@/components/BottomSection.vue';
export default {
  data(){
    return{
      firstLevelPath: '/main', 
      searchtext:'',
    }
  },
  components:{
    BottomSection,
  },
  watch: {
    '$route': {
      handler() {
        this.updateFirstLevelPath();
      },
      immediate: true, // 立即执行一次
    },
  },
  methods: {
    search() {
      if(this.$route.path!=='/'+this.searchtext){
        this.$router.push('/'+this.searchtext);
        this.searchtext=""
      }
    },
    updateFirstLevelPath() {
      const matchedRoutes = this.$route.matched;

      // 提取第一级路径
      if (matchedRoutes.length > 0) {
          this.firstLevelPath = matchedRoutes[0].path;
        }
        console.log(matchedRoutes[0].path);
      
    },
    window(){
      window.scrollTo(0, 100);
    },
    contact(){
      window.scrollTo(0, 800);
      if (this.$route.path !== '/main') {
        this.$router.push('/main');
        this.$nextTick(() => {
          window.scrollTo(0, 800);
      });
      }
      
    },
    handleSelect(key, keyPath) {
        console.log(key, keyPath);
      }
},
}

</script>

<style>
.top{
  height: 100px;
  background-color: rgb(183, 230, 241);
  background-image: url('@/assets/top.png');
  background-repeat: no-repeat;
  background-position:left top;
  background-size: 600px;
  width: 100%; 

}
.toptext{
  line-height: 100px;
  font-size: 50px;
  font-family:"Comic Sans MS", cursive, serif, sans-serif;
  color:#417D9B;
  
}
.input {
  width: 15%;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  position: absolute;
  right: 6.5%;
  padding: 10px 0px;
  top: 30px;
}
.link{
  line-height: 100px;
  position: absolute;
  font-size: 15px;
  font-family:"";
  color:#223ea5;
  text-decoration: none;
}
.l{
  line-height: 100px;
  position: absolute;
  font-size: 20px;
  font-family:"";
  color:#223ea5;
  text-decoration: none;
}
.link:hover{
  color:rgb(141, 164, 240);
  cursor: pointer;
}
.sticky-menu {
  position: sticky;
  top: 0; /* 固定在页面顶部 */
  background-color: #f1f1f1;
  z-index: 5;
}
</style>
