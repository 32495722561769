<template>
  <div class="img">
    <img src="@/assets/school.jpg" alt="" style="height: 600px;">
  </div>
</template>

<script>
export default {
    name:'ToolFirst',
}
</script>

<style scoped>
.img{
  margin: auto;
  position: relative;
  left:9%;
  top:3%
}
</style>