<template>
    <div class="rightright">
      <Tap></Tap>
      
    </div>
</template>

<script>
import Tap from './Tap.vue';
export default {
  components:{
      Tap,
  }
}
</script>

<style>
.rightright{
    flex: 1;

}
  
</style>