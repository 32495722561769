<template>
  <div class="rb">
    <RightLeft></RightLeft>
    <RightRight></RightRight>
  </div>
</template>

<script>
    import RightRight from '@/components/RightRight.vue';
    import RightLeft from '@/components/RightLeft.vue';
    export default {
      components: {
        RightRight,
        RightLeft ,
    }
    }
</script>

<style>
.rb{
    margin: 10px;
    display: flex;
    flex-direction: row;
}
</style>