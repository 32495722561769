<template>
  <div class="wrong">
    <h1>404NotFind</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.wrong{
  height: 100vh;
}
h1{
  font-size: 100px;
}
</style>