<template>
    <div class="timer">
        <br>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="计时器" name="first"></el-tab-pane>
            <el-tab-pane label="倒计时" name="second"></el-tab-pane>
        </el-tabs>
    <el-container>

      <el-main>
        <div v-if="whatTimer">
            <h1>计时器</h1>
            <br>
          <p v-if="!timerRunning">计时: {{ formatTime(timer) }}</p>
          <br>
          <p v-if="timerRunning">剩余时间: {{ formatTime(remainingTime1) }}</p>
          <br>
          <el-button @click="startTimer" :disabled="timerActive" type="primary">开始</el-button>
          <el-button @click="pauseTimer" :disabled="!timerActive" type="warning">暂停</el-button>
          <el-button @click="resetTimer" type="danger">重置</el-button>
        </div>
        
        <div v-if="!whatTimer">
            <h1>倒计时</h1>
            <br>
          <div>
            <label for="timerInput">输入时间（秒）：</label>
            <br><br>
            <el-input-number v-model="inputTime" id="timerInput" style="width: 30%;"></el-input-number>
            <br><br>
            <el-button @click="startDownTimer" type="success" v-show="!timerRunning">开始计时</el-button>
          </div>
          <div v-if="timerRunning">
            <p>剩余时间：{{ formatTime(remainingTime2) }} 秒</p>
            <br>
            <el-button @click="stopDownTimer" type="danger">停止计时</el-button>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>

export default {
    name: 'TB',
    data() {
    return {
        activeName: 'first',
        whatTimer: true,
        timer: 0,
        timerActive: false,
        inputTime: 0,
        remainingTime1: 0,
        remainingTime2: 0,
        timerRunning: false,
        timerInterval: null,
      };
    },
    methods: {
        change() {
          this.whatTimer = !this.whatTimer;
          this.resetTimer();
          this.stopDownTimer();
        },
        startTimer() {
          this.stopDownTimer();
          this.$nextTick(() => {
              this.timerActive = true;
              this.timerInterval = setInterval(() => {
              this.timer++;
          }, 1000);
        });
        this.$message({
          showClose: true,
          message: '开始计时'
        });
        },
        pauseTimer() {
          this.timerActive = false;
          clearInterval(this.timerInterval);
        },
        resetTimer() {
          this.timer = 0;
          this.timerActive = false;
          clearInterval(this.timerInterval);
        },
        startDownTimer() {
          if (this.inputTime > 0) {
            this.remainingTime2 = this.inputTime;
            this.timerRunning = true; 
            this.$message({
                showClose: true,
                 message: '开始计时'
            });
            this.timerInterval = setInterval(() => {
              if (this.remainingTime2 > 0) {
                this.remainingTime2 -= 1;
              } else {
                this.stopDownTimer();
                this.$message({
                showClose: true,
                message: '倒计时结束',
                type: 'success'
        });
              }
            }, 1000);
          }
        },
        stopDownTimer() {
          clearInterval(this.timerInterval);
          this.timerRunning = false;
        },
        formatTime(seconds) {
          const minutes = Math.floor(seconds / 60);
          const remainingSeconds = seconds % 60;
          return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
        },
        handleClick(){
            this.resetTimer();
            this.stopDownTimer();
            if(this.activeName==="first"){
                this.whatTimer=true;
            }else{
                this.whatTimer=false;
            }
        },
    
    },
};
</script>

<style scoped>
.timer{
  font-size: 20px;
  margin: 30px;
  padding: 20px;
}
</style>
  